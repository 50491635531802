// import { AnimatedSprite } from 'animated-sprite';

import React, { useEffect } from 'react';
import { SpriteAnimation } from "./../utlis/SpriteAnimation";


function App() {

  useEffect(() => {
    let spriteAnimation = new SpriteAnimation("coinAnimation", require('../images/lightnings.png'));
    spriteAnimation.start();
  });

  return (
    <div>
      <canvas id="coinAnimation"></canvas>
    </div>
  );
}

export default App;
