export class SpriteAnimation {
    constructor(id , image) {
        this.numColumns = 6;
        this.numRows = 10;
        this.sprite = {
            width: 1344, height: 1260
        }
        this.frameWidth = this.sprite.width / this.numColumns;
        this.frameHeight = this.sprite.height / this.numRows;
        this.canvas = document.getElementById(id);
        if(this.canvas) {
            this.context = this.canvas.getContext("2d");
            this.canvas.width = 224;
            this.canvas.height = 126;
        }
        
        this.srcImage = new Image();
        this.srcImage.src = image;
        this.fps = 0;
        this.fpsInterval = 0;
        this.startTime = 0;
        this.now = 0;
        this.then = 0;
        this.elapsed = 0;
        this.currentFrame = 0;
        this.fps = 24;
        this.fpsInterval = 1000 / this.fps;
        this.srcImageLoaded = false;
        this.startAnimationAtferSrcImageLoaded = false;
        this.srcImage.addEventListener("load", this.onSrcImageLoaded());
    }
    onSrcImageLoaded() {
        this.srcImageLoaded = true;
        if(this.startAnimationAtferSrcImageLoaded) {
            this.start();
            this.startAnimationAtferSrcImageLoaded = false;
        }
    }
    start() {
        if(!this.srcImageLoaded) {
            this.startAnimationAtferSrcImageLoaded = true;
            return;
        }
        this.currentFrame = 0;
        this.then = Date.now();
        this.startTime = this.then;
        this.animate();
    }
    animate() {
        let self = this;
        this.animationFrame = window.requestAnimationFrame(function() {
            self.animate();
        });
        this.now = Date.now();
        this.elapsed = this.now - this.then;
        if (this.elapsed > this.fpsInterval) {
            this.then = this.now - (this.elapsed % this.fpsInterval);
            this.render();
        }
    }
    render() {
        this.currentFrame++;
        this.maxFrame = this.numColumns * this.numRows - 1;
        if (this.currentFrame > this.maxFrame) {
            this.stop();
        }
        let column = this.currentFrame % this.numColumns;
        let row = Math.floor(this.currentFrame / this.numColumns);
        if(this.canvas && this.context) {
            this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.context.drawImage(this.srcImage, column * this.frameWidth, row * this.frameHeight, this.frameWidth, this.frameHeight, 0, 0, this.frameWidth, this.frameHeight);
        }
    }
    stop() {
        if(this.animationFrame) cancelAnimationFrame(this.animationFrame);
        this.onComplete()?.();
    }
    setOnCompleteListener(callback) {
        this.onComplete = callback
    }
}