// import { ReactComponent as SearchSVG } from "./../svg/search.svg"
import { ReactComponent as SparkSVG } from "svg/evolution/spark.svg"
import { ReactComponent as BalanceSVG } from "svg/evolution/balance.svg"
import { ReactComponent as UserSVG } from "svg/evolution/user.svg"
import { ReactComponent as MenuSVG } from "svg/evolution/menu.svg"
import { ReactComponent as SearchSVG } from "svg/evolution/search.svg"

import './Navbar.scss';

function Navbar() {
    return (
        <nav className="navbar navbar-expand-lg navbar-main">
            <div className="container-fluid">
                <a className="navbar-brand" href="#">Fairplay</a>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0" style={{ columnGap: "0.5rem" }}>
                        <li className="nav-item">
                            <a className="nav-link navbar-button" aria-current="page" href="#"><SparkSVG/>&nbsp;&nbsp;For You</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link navbar-button active" aria-current="page" href="#">Games</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link navbar-button" aria-current="page" href="#">My List</a>
                        </li>
                    </ul>
                    <div className="header-controls">
                        <div className="search-control">
                            <SearchSVG className="search-icon text-white"/>
                            <input type="text" className="form-control" placeholder="Username" style={{ width: "330px" }}></input>
                        </div>
                        <div className="d-flex align-items-center">
                            <BalanceSVG className="me-2"/>
                            <span style={{color: '#fc0'}}>$0.01</span>
                        </div>
                        <div className="d-flex align-items-center">
                            <UserSVG className="me-2"/>
                            45,588
                        </div>
                        <div>
                            <button className="btn text-white navbar-button rounded-circle" style={{ padding: "0.5rem" }}>
                                <div style={{ width: '24px', height: '24px' }}>
                                    <MenuSVG/>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
  }
  
  export default Navbar;
  