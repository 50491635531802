import Navbar from "../../components/Navbar/Navbar"
import { ReactComponent as IndiaSVG } from "svg/evolution/india.svg"
import { ReactComponent as UserSVG } from "svg/evolution/user.svg"

import React, { useEffect } from 'react';
import { SpriteAnimation } from "../../utlis/SpriteAnimation";

import './Lobby.scss';

function App() {

    let games = 
    [
      {
        title: 'Hindi Lightning Roulette',
        image: require('images/game-1.jpeg'),
        price: '2',
        users: parseInt(Math.random() * 100000).toLocaleString('en-IN')
      }, 
      {
        title: 'Lightning Roulette',
        image: require('images/game-2.jpeg'),
        price: '2',
        users: parseInt(Math.random() * 100000).toLocaleString('en-IN')
      },
      {
        title: 'XXXtreme Lightning Roulette',
        image: require('images/game-3.jpeg'),
        price: '2',
        users: parseInt(Math.random() * 100000).toLocaleString('en-IN')
      },
      {
        title: 'Hindi Roulette',
        image: require('images/game-4.jpeg'),
        price: '5',
        users: parseInt(Math.random() * 100000).toLocaleString('en-IN')
      },
      {
        title: 'Immersive Roulette',
        image: require('images/game-5.jpeg'),
        price: '5',
        users: parseInt(Math.random() * 100000).toLocaleString('en-IN')
      },
      {
        title: 'Instant Roulette',
        image: require('images/game-6.jpeg'),
        price: '1',
        users: parseInt(Math.random() * 100000).toLocaleString('en-IN')
      },
      {
        title: 'Roulette',
        image: require('images/game-7.jpeg'),
        price: '5',
        users: parseInt(Math.random() * 100000).toLocaleString('en-IN')
      },
      {
        title: 'VIP Roulette',
        image: require('images/game-8.jpeg'),
        price: '10',
        users: parseInt(Math.random() * 100000).toLocaleString('en-IN')
      },
    ];

    function randomBetween(min, max) { // min and max included 
      return Math.floor(Math.random() * (max - min + 1) + min)
    }

    games.forEach((game, i) => {
      let history = [];
      for(let j=0; j<13; j++) {
        history.push(randomBetween(0, 36));
      }
      games[i].history = history;
    })

    

    function onComplete() {
      isAnimationRunning = false;
      startNewAnimation();
    } 

    let isAnimationRunning = false;

    function startNewAnimation() {
      if(isAnimationRunning) return;
      isAnimationRunning = true;
      let random = randomBetween(1,games.length);
      let spriteAnimation = new SpriteAnimation(`canvas-${random}`, require('../../images/lightnings.png'));
      spriteAnimation.setOnCompleteListener(onComplete)
      spriteAnimation.start();
    }

    useEffect(() => {
      startNewAnimation();
    });

    return (
      <div>
        <Navbar/>

        <div className="container-fluid mt-5">
            
            <div className="category-header">
                <div style={{flexGrow: 1}}>Roulette</div>
                <div className="d-flex align-items-center" style={{ columnGap: "0.5rem" }}>
                  <button className="btn btn-outline-light btn-sm">Native Dealer</button>
                  <button className="btn btn-outline-light btn-sm">Speed</button>
                  <button className="btn btn-outline-light btn-sm">Auto</button>
                  <button className="btn btn-outline-light btn-sm">Land-based</button>
                </div>
            </div>

            <div className="row row-cols-2 row-cols-sm-3 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 mt-4">
              {games.map((game, index) => {
                return (
                  <div className="col" key={index}>
                    <div className="game-card">
                      <div className="thumbnail-holder">
                        <canvas id={"canvas-" + index}></canvas>
                        <img className="thumbnail w100" src={game.image}></img>
                        <div className="gradient"></div>
                        <div className="history">
                          {game.history.map((history, j) => {
                            let color = "#ccc";
                            if (history == 0) {
                              color = "green";
                            }
                            if(Math.random() < 0.5) {
                              color = "#ff412e"
                            }

                            return(
                              <div className="history-item" key={j}>
                                <svg  fill="transparent" overflow="visible" viewBox="0 0 64 64">
                                  <defs>
                                    <linearGradient id="multiplier-gradient" x1="0" x2="0" y1="0" y2="1"><stop offset="0%" stopColor="#C8A153"></stop><stop offset="100%" stopColor="#FBDB63"></stop></linearGradient>
                                    <linearGradient id="multiplier-gradient-red" x1="0" x2="0" y1="0" y2="1"><stop offset="0%" stopColor="#A90004D9"></stop><stop offset="100%" stopColor="#FFABAED9"></stop></linearGradient>
                                  </defs>
                                  {j == 0 ? <rect width="60" height="60" x="2" y="2" rx="15" ry="15" stroke={color} strokeWidth="4"></rect> : null}
                                  <text x="50%" y="50%" fill={color} fontFamily="Inter, sans-serif" fontSize="40" fontWeight="600" alignmentBaseline="central" dominantBaseline="central" textAnchor="middle">{history}</text>
                                </svg>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      <div className="title">
                        { game.title }
                        { Math.random() < 0.5 ? <IndiaSVG className="country-flag"/> : null } 
                      </div>
                      <div className="d-flex align-items-center">
                        <span className="price">$2</span>
                        <span className="users">
                          <UserSVG height={18} width={18}/>
                          <span>{game.users}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                )
              })}
              
            </div>

        </div>

        

      </div>
    );
  }
  
  export default App;
  